<template>
	<div>
		<div class="headers">
			个人中心
		</div>
		<div class="infoWrap">
			<div class="flex align-center">
				<img v-if="!info" :src="headerImage"
					style="width: 88px;height: 88px;border-radius: 50%;">
				<img v-else :src="userInfo.avatar!=''?host+userInfo.avatar:headerImage"
					style="width: 88px;height: 88px;border-radius: 50%;">
				<div style="margin: 0 19px 0 17px;">
					<div v-if="info">
						<div style="color: #2B2A25;font-size: 14px;font-weight: bold;">{{userInfo.mobile}}</div>
						<div style="color: #2B2A25;font-size: 12px;margin-top: 4px;">账号:   {{userInfo.mobile}}</div>
						<div style="color: #7F7E7D;font-size: 10px;margin-top: 8px;">
							{{userInfo.sign==null?sign:userInfo.sign}}
						</div>
					</div>
					<div v-else style="color: #2B2A25;font-size: 10px;">暖心冬季月，瑞士大回馈！快快开启你的瑞士</div>
				</div>
			</div>
			<div @click="logout" class="btn" v-if="info">退出</div>
			<div @click="loginShow = true" v-else class="btn" style="border: 1px solid #0F7DFF;color: #0F7DFF;">登录</div>
		</div>
		<div class="navContent">
			<div @click="goUrl(item.url)" class="navChild" v-for="(item,index) in navList" :key='index'>
				<img :src="item.icon" style="width: 18px;height: 16px;">
				<div style="color: #2B2A25;font-size: 14px;margin-left: 6px;">{{item.name}}</div>
			</div>
		</div>
		<TabBar></TabBar>


		<div class="mask" v-if="loginShow">
			<img src="../../assets/image/luckDraw/close.png" class="close" @click="loginShow = false">
			<div class="maskChild">
				<div class="maskTop">
					欢迎参加2022瑞士冰雪节
				</div>
				<div class="maskPhone">
					<input @input="checkInput" v-model="phone" type="number" placeholder="请输入账号" class="phones" />
				</div>
				<div class="maskPhone">
					<input @input="checkInput" v-model="code" type="password" placeholder="请输入密码" class="phones" />
				</div>
				<div class="xy" style="height: 20px;">
				</div>
				<div style="position: relative;">
					<div @click="login" class="loginBtn" :style="{background:inputStatus?'#DD1C25':'#86838C'}">
						{{types?'注册':'登录'}}
					</div>
					<div @click="Setypes"
						style="position: absolute;top: 28%;right: 7%;color: #86838C;font-size: 12px;letter-spacing: 1px;">
						{{!types?'注册':'登录'}}
					</div>
				</div>
				<div style="color: #434444;font-size: 12px;text-align: center;">登录即可获得一次抽奖机会哦～</div>
			</div>
		</div>
	</div>
</template>

<script>
	import {
		index
	} from '../../api/mine.js'
	import {
		register,
		login
	} from '../../api/login.js'
	import{
		host
	} from '../../utils/host.js'
	import TabBar from '@/components/tabBar.vue'
	export default {
		data() {
			return {
				host:host,
				headerImage: require('../../assets/image/mine/headerImg.png'),
				types: false, //false是登录  true是注册
				inputStatus: false, //是否填完input
				loginShow: false,
				sign: '这个人很懒什么都没留下~',
				info: false,
				navList: [{
						icon: require('../../assets/image/mine/address.png'),
						name: '收货地址',
						url: '/myAddress'
					},
					{
						icon: require('../../assets/image/mine/setUp.png'),
						name: '个人资料',
						url: '/mineInfo'
					},
					{
						icon: require('../../assets/image/mine/gift.png'),
						name: '中奖记录',
						url: '/giftHistory'
					}
				],
				token: window.localStorage.getItem('token'),
				userInfo: {},
				phone: '',
				code: ''
			}
		},
		components: {
			TabBar
		},
		created() {
			if (this.token) {
				this.getInfo()
			}
		},
		methods: {
			login() {
				if (!this.inputStatus) {
					return
				}
				let data = new FormData()
				data.append('mobile', this.phone)
				data.append('password', this.code)
				if (this.types) {
					register(data).then(res => {
						console.log('注册成功', res)
						this.loginShow = false
						window.localStorage.setItem('token', res.data.userinfo.token)
						this.$toast('注册成功');
						this.info = true
						this.userInfo = res.data.userinfo
					})
				} else {
					login(data).then(res => {
						console.log('登录成功', res)
						this.loginShow = false
						window.localStorage.setItem('token', res.data.userinfo.token)
						this.$toast('登录成功');
						this.info = true
						this.userInfo = res.data.userinfo
					})
				}
			},
			Setypes() {
				this.types = !this.types
			},
			// 检查是否填写完所有input
			checkInput() {
				if (this.phone && this.code) {
					this.inputStatus = true
				} else {
					this.inputStatus = false
				}
			},
			getInfo() {
				let data = new FormData()
				data.append('token', this.token)
				index(data).then(res => {
					console.log('我的个人信息', res)
					this.userInfo = res.data
					this.info = true
				})
			},
			logout() {
				this.info = false
				window.localStorage.removeItem('token');
			},
			goUrl(url) {
				this.$router.push({
					path: url
				})
			}
		}
	}
</script>

<style scoped>
	.maskChild {
		width: 280px;
		height: 255px;
		background: #FFFFFF;
		border-radius: 12px;
		position: fixed;
		top: 35%;
		left: 15%;
	}

	.maskChildTop {
		width: 222px;
		height: 53px;
		position: fixed;
		top: 32.5%;
		left: 22%;
		z-index: 2;
	}

	.maskChildWz {
		font-size: 14px;
		color: #EF6937;
		margin-top: 46px;
		text-align: center;
	}

	.CjBtn {
		width: 168px;
		height: 38px;
		background: #DD1C25;
		box-shadow: 0px 2px 14px 0px rgba(182, 181, 181, 0.5);
		border-radius: 19px;
		line-height: 38px;
		text-align: center;
		font-size: 16px;
		letter-spacing: 2px;
		margin: 0 auto;
		margin-top: 12px;
		color: #fff;
	}

	.close {
		width: 30px;
		height: 30px;
		position: fixed;
		top: 30%;
		right: 11%;
	}

	.back {
		width: 10px;
		height: 17px;
		position: fixed;
		top: 6%;
		z-index: 2;
		left: 5%;
	}

	.dtBtn {
		width: 150px;
		height: 24px;
		background: #DD1C25;
		box-shadow: 0px 2px 14px 0px rgba(182, 181, 181, 0.5);
		border-radius: 19px;
		text-align: center;
		line-height: 24px;
		color: #fff;
		letter-spacing: 1px;
		font-size: 14px;
		margin: 0 auto;
		margin-top: 10px;
	}

	.maskTop {
		color: #434444;
		font-size: 20px;
		font-weight: bold;
		text-align: center;
		padding-top: 18px;
		box-sizing: border-box;
	}

	.maskPhone {
		height: 32px;
		border-radius: 21px;
		border: 1px solid #979797;
		margin: 0 auto;
		margin: 14px 17px 0 17px;
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.phones {
		text-align: center;
		width: 90%;
		font-size: 14px;
	}

	input::-webkit-input-placeholder {
		color: #AEADB1;
	}

	input::-moz-placeholder {
		/* Mozilla Firefox 19+ */
		color: #AEADB1;
	}

	input:-moz-placeholder {
		/* Mozilla Firefox 4 to 18 */
		color: #AEADB1;
	}

	input:-ms-input-placeholder {
		/* Internet Explorer 10-11 */
		color: #AEADB1;
	}

	.maskCodeWrap {
		margin: 12px 17px 0 17px;
		display: flex;
		justify-content: space-between;
		align-items: center;
	}

	.maskCodeLeft {
		width: 161px;
		height: 32px;
		text-align: center;
		border-radius: 20px;
		border: 1px solid #979797;
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.maskCodeRight {
		width: 75px;
		height: 32px;
		border-radius: 20px;
		text-align: center;
		line-height: 32px;
		font-size: 12px;
		color: #fff;
	}

	.xy {
		margin: 8px 17px 0 17px;
	}

	.checkBoxs {
		width: 16px;
		height: 16px;
		border-radius: 4px;
		border: 1px solid #979797;
	}

	.loginBtn {
		width: 168px;
		height: 38px;
		box-shadow: 0px 2px 14px 0px rgba(182, 181, 181, 0.5);
		border-radius: 19px;
		margin: 0 auto;
		color: #fff;
		text-align: center;
		line-height: 38px;
		margin-top: 9px;
	}

	.flex {
		display: flex;
	}

	.headers {
		text-align: center;
		margin-top: 13px;
		color: #434444;
		font-size: 16px;
		font-weight: bold;
	}

	.infoWrap {
		margin: 30px 16px 0 30px;
		display: flex;
		justify-content: space-between;
	}

	.btn {
		width: 40px;
		height: 20px;
		border-radius: 4px;
		border: 1px solid #7F7E7D;
		line-height: 20px;
		text-align: center;
		color: #7F7E7D;
		font-size: 12px;
	}

	.navContent {
		margin: 24px 16px 0 16px;
		background: #FFFFFF;
		box-shadow: 0px 2px 12px 0px rgba(148, 148, 149, 0.5);
		border-radius: 8px;
		padding: 6px 14px;
		box-sizing: border-box;
	}

	.navChild {
		width: 100%;
		display: flex;
		border-bottom: 1px solid #D8D8D8;
		height: 48px;
		align-items: center;
	}

	.navChild:last-child {
		border: none;
	}
</style>
