import request from "@/utils/request"
// 登录
export function login(data) {
	return request({
		url: '/api/User/login',
		method: 'post',
		data
	})
}
// 注册
export function register(data) {
	return request({
		url: '/api/User/register',
		method: 'post',
		data
	})
}
